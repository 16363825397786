// src/components/Seo.js
import React from 'react';
import { Helmet } from 'react-helmet';

const Seo = ({ product }) => {
    const productSchema = {
        "@context": "https://schema.org/",
        "@type": "Product",
        "name": product.name,
        "image": [product.image],
        "description": product.description,
        "sku": product.sku,
        "brand": {
            "@type": "Brand",
            "name": product.brand
        },
        "offers": {
            "@type": "Offer",
            "url": product.url,
            "priceCurrency": "PKR",
            "price": product.price,
            "itemCondition": "https://schema.org/NewCondition",
            "availability": `https://schema.org/${product.availability}`,
            "shippingDetails": {
                "@type": "OfferShippingDetails",
                "shippingRate": {
                    "@type": "MonetaryAmount",
                    "value": "0.00",
                    "currency": "PKR"
                },
                "shippingDestination": {
                    "@type": "DefinedRegion",
                    "name": "Pakistan"
                }
            },
            "hasMerchantReturnPolicy": {
                "@type": "MerchantReturnPolicy",
                "returnPolicyCategory": "https://schema.org/Refund"
            }
        },
        "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": product.rating >= 1 && product.rating <= 5 ? product.rating : 5, // Ensuring the rating is between 1 and 5
            "reviewCount": product.numReviews > 0 ? product.numReviews : 1 // Ensuring review count is positive
        },
        "review": product.reviews.map(review => ({
            "@type": "Review",
            "author": {
                "@type": "Person",
                "name": review.name
            },
            "reviewBody": review.comment,
            "reviewRating": {
                "@type": "Rating",
                "ratingValue": review.rating.toString() // Ensuring rating is stringified
            }
        }))
    };

    return (
        <Helmet>
            {/* Basic Seo */}
            <title>{`${product.name}`}</title>
            <meta name="description" content={`${product.description} - Buy Products in Pakistan`}/>
            <meta name="keywords" content="Honey, Dry Fruit, Healthy Snacks, Organic, Khalis Products, Pure Desi Honey, Organic Food Pakistan" />

            {/* Open Graph / Facebook */}
            <meta property="og:type" content="product" />
            <meta property="og:title" content={`${product.name}`} />
            <meta property="og:description" content={product.description} />
            <meta property="og:image" content={product.image} />
            <meta property="og:url" content={product.url} />
            <meta property="og:site_name" content="Nature's Essence" />
            <meta property="og:locale" content="en_US" />

            {/* Twitter */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={`${product.name}`} />
            <meta name="twitter:description" content={product.description} />
            <meta name="twitter:image" content={product.image} />
            <meta name="twitter:site" content="@NaturesEssence" />
            <meta name="twitter:creator" content="@NaturesEssence" />

            {/* Pinterest */}
            <meta property="og:type" content="product.item" />
            <meta property="og:price:amount" content={product.price} />
            <meta property="og:price:currency" content="PKR" />
            <meta property="product:category" content={product.category} />

            {/* LinkedIn */}
            <meta name="linkedin:card" content="summary" />
            <meta name="linkedin:title" content={`${product.name}`} />
            <meta name="linkedin:description" content={product.description} />
            <meta name="linkedin:image" content={product.image} />
            <meta name="linkedin:url" content={product.url} />

            {/* JSON-LD Schema Markup */}
            <script type="application/ld+json">
                {JSON.stringify(productSchema)}
            </script>
        </Helmet>
    );
};

export default Seo;
